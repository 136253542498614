var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "90%", "max-width": "450px" }
            },
            [
              _c(
                "v-row",
                { staticClass: "mt-12" },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/img/logo.gif"),
                          width: "100%",
                          "max-width": "200px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "20px", "margin-top": "12px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("systemName")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "text-center fx-20 mx-auto my-0",
                    staticStyle: { "font-size": "16px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("description.maintenance")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "text-center fx-20 mx-auto my-0",
                    staticStyle: { "font-size": "16px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("description.maintenanceMessage")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }